.ViewTemplate {
}

.ViewTemplate h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
}

.ViewTemplate h3 {
  font-size: 18px;
  line-height: 33px;
  color: #0b2e53;
  margin: 0px;
  margin-bottom: 20px;
}

.ViewTemplate p {
  position: relative;
  bottom: 8px;
  white-space: pre-wrap;
  opacity: 1;
}

.ViewTemplate h4 {
  position: relative;
  bottom: 10px;
  margin: 0px;
}
