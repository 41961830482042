.circle {
  width: 28px;
  height: 28px;
  background: #fff;
  padding: 5px;
  margin-right: 8px;
  border-radius: 50px;
}

svg {
  position: relative;
  top: 3px;
}

.Accounts h2 {
  font-weight: 300;
  font-size: 21px;
  padding: 15px 25px;
}

.Accounts h3 {
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 14px !important;
}

.accountsColumn {
  position: relative;
}

.accountsOverlay {
  background: #fff;
  position: absolute;
  top: -5px;
  width: 100%;
  right: 5px;
  z-index: 999;
}

.accountsOverlay h3 {
  padding: 5px;
}

.accountsOverlay h3:hover {
  background: #00D3FB;
  color: #fff;
}

.Accounts h4 {
  font-size: 14px;
  font-weight: 200;
  margin: 15px 0px;
  opacity: 0.8;
  letter-spacing: 0.5px;
}

.Accounts h4 svg {
  margin-right: 10px;
  z-index: 0 !important;
}

.Accounts h4:hover {
  color: #000;
  cursor: pointer;
  opacity: 1;
}

.Accounts h4.active {
  color: #00D3FB;
  opacity: 1;
  font-weight: 700;
}

.cardComponent {
  background-color: #fff;
  width: 100%;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  min-height: 600px;
  overflow-x: hidden;
}

.cardComponent .home h3 {
  font-size: 20px;
  padding: 20px;
  font-weight: 400;
}

.cardComponent .home .step p {
  color: #00D3FB;
}

.cardComponent .home .step {
  padding: 20px;
  border-bottom: 1px solid #bdc3c7;
  margin: 0px;
  cursor: pointer;
}
.cardComponent .home .step:hover {
  background-color: #f8fcff;
  transition: 0.5s all ease;
}

.referLink {
  background-color: #e3e8ee;
  padding: 10px;
}

.step h4 {
  margin: 0;
  padding: 15px 0px;
}
