@font-face {
  font-family: "TradeGothic";
  src: local("TradeGothic"),
    url("./Fonts/trade-gothic-bold.woff") format("woff");
}

@font-face {
  font-family: "Graphik-Regular";
  src: local("Graphik-Regular"),
    url("./Fonts/Graphik-Regular.woff") format("woff");
}

@font-face {
  font-family: "Graphik-Light";
  src: local("Graphik-Light"), url("./Fonts/Graphik-Light.woff") format("woff");
}

@font-face {
  font-family: "GraphikMedium";
  src: local("GraphikMedium"), url("./Fonts/Graphik-Medium.woff") format("woff");
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Graphik-Regular";
  margin: 0px;
  padding: 0px;
  color: #333;
  background-color: #e3e8ee !important;
}

.conclusion {
  background-color: #33353a;
  color: #fff;
  margin-bottom: 0px;
  padding: 40px 0px;
}

.conclusion h1 {
  font-family: "GraphikMedium", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
}

.conclusion p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 30px;
}

.footer-nav {
  display: flex;
  padding: 80px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #33353a;
  color: #fff;
}

@media only screen and (max-width: 800px) {
  .footer-nav .container {
    width: 100%;
  }
  .footer-nav {
    padding: 50px 0px;
  }
}

.sections-menu-headline {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  line-height: 26px;
}

.structure-menu-link {
  display: block;
  margin-bottom: 22px;
  -webkit-transition: color 0.2s ease, -webkit-transform 0.4s ease;
  transition: color 0.2s ease, -webkit-transform 0.4s ease;
  transition: color 0.2s ease, transform 0.4s ease;
  transition: color 0.2s ease, transform 0.4s ease, -webkit-transform 0.4s ease;
  color: #8792a1;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.structure-menu-link:hover {
  color: #fff;
  text-decoration: none;
}

.copy {
  display: flex;
  height: 60px;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #33353a;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(hsla(0, 0%, 100%, 0.05)),
    to(hsla(0, 0%, 100%, 0.05))
  );
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0.05)
  );
}

.copy-inner {
  font-size: 14px;
  font-weight: 500;
  color: hsla(0, 0%, 100%, 0.37);
  line-height: 26px;
}

.white a {
  color: #fff !important;
}

.secondary-btn {
  color: #fff;
  background-color: #00D3FB;
  padding: 25px 70px;
  border: none;
  font-weight: 600;
  font-size: 22px;
}

.secondary-btn:hover,
.secondary-btn:active,
.secondary-btn:focus {
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
  transition: 0.2s all ease;
}

.navbar-default {
  background-color: transparent;
  z-index: 9;
  border: none;
}

.navbar-default .btn-primary {
  padding: 10px 40px;
  position: relative;
  bottom: 3px;
}

.navbar-nav > li a {
  padding-right: 0px;
  padding-left: 35px;
  font-size: 18px;
  font-weight: 300;
}

@media only screen and (max-width: 800px) {
  .navbar-nav > li a {
    padding-left: 5px;
  }
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-default .navbar-nav > li > a:hover {
  opacity: 0.7;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #00D3FB;
  background-color: #fff;
}

.navbar-brand {
  padding: 12px 0px;
}

.navbar-toggle {
  margin-right: 0px;
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  margin-left: 0px;
}

.btn-primary {
  padding: 15px 40px;
  border: none;
  font-weight: 700 !important;
  font-size: 22px;
  color: #fff;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-transform: none;
  background: #00D3FB;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-weight: 100;
  width: 100%;
  font-size: 18px;
  margin-top: 15px;
  border-radius: 0px;
  text-align: center;
}

.btn-primary:hover {
  text-decoration: none;
  opacity: 0.7;
}

h1 {
  font-weight: 800;
  font-size: 45px;
  line-height: 50px;
  color: #33353a;
  font-family: "GraphikMedium", sans-serif;
  margin: 10px 0px;
  font-weight: 300;
}

h4 {
  font-weight: 600;
  margin: 0px;
  font-size: 14px !important;
  font-size: 18px;
  line-height: 21px;
  color: #0b2e53;
}

h5 {
  font-size: 16px;
  line-height: 10px;
}

.container-fluid > .navbar-collapse,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container > .navbar-header {
  margin-right: 0px;
  margin-left: 0px;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: none;
  padding: 20px 10px;
  font-size: 14px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 12px;
  padding: 5px;
  width: 30px !important;
}

input[type="number"],
input[type="text"],
input[type="password"],
input[type="email"] {
  min-height: 27px !important;
}

.react-datepicker-wrapper input[type="number"],
.react-datepicker-wrapper input[type="text"] {
  text-align: center;
}

.dateTitle {
  background: #fff;
  border: 1px solid #33353a;
  border-right: none;
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  position: relative;
  bottom: 1px;
  font-size: 12px;
  font-weight: 300;
  background: #33353a;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0.05)
  );
  color: #fff;
  border: none;
  position: relative;
  left: 2px;
}

.react-datepicker__input-container {
  font-size: 13px;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  background: #fff !important;
}

button:hover {
  color: #00D3FB;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.btn-secondary {
  background-color: #00D3FB;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border: none;
  min-width: 100px;
  display: block;
  max-width: 200px;
  text-align: center;
}

.btn-secondary:disabled,
.other-btn:disabled {
  opacity: 0.6;
}

.btn-secondary svg {
  color: #fff;
}

.btn-secondary:hover {
  text-decoration: none !important;
  color: #fff;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

th {
  text-transform: uppercase;
}

.other-btn {
  color: #444444;
  background: #f3f3f3;
  border: 1px #dadada solid;
  padding: 5px 10px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
  outline: none;
  cursor: pointer;
}

.other-btn:hover {
  border: 1px #c6c6c6 solid;
  box-shadow: 1px 1px 1px #eaeaea;
  color: #333333;
  background: #f7f7f7;
  text-decoration: none !important;
}

.control-label {
  float: left;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 14px !important;
  font-weight: 200;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  padding: 12px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.table {
  width: 100.1%;
  max-width: 100.1%;
  margin-bottom: 0px;
  overflow-x: hidden;
  margin: auto;
}

.footer {
  padding-top: 150px;

}

.footer p {
  text-align: center;
  width: 100%;
}

.footer span {
  cursor: pointer;
}