.Broadcast h4.POSITIVE {
  border-left: 3px solid #2ecc71;
  padding-left: 5px;
}

.Broadcast h4.none {
  border-left: 3px solid #2ecc71;
  padding-left: 5px;
}

.Broadcast h4.NEUTRAL {
  border-left: 3px solid #f1c40f;
  padding-left: 5px;
}

.Broadcast h4.NEGATIVE {
  border-left: 3px solid #e74c3c;
  padding-left: 5px;
}

.Broadcast h4 {
  padding: 10px !important;
}
