.NewAccount {
  padding: 20px;
}

.NewAccount h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
  margin-top: 0px;
}

.NewAccount h3 {
  font-size: 18px;
  line-height: 33px;
  color: #0b2e53;
  margin: 0px;
  margin-bottom: 20px;
}

.NewAccount p {
  opacity: 1;
  margin-bottom: 0px;
}

.NewAccount form {
  padding-bottom: 15px;
}

.NewAccount form textarea {
  height: 300px;
  font-size: 24px;
}

.NewAccount p {
  opacity: 1;
}
