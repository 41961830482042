.NewCampaign {
  padding: 20px;
}

.NewCampaign .other-btn {
  margin-top: 10px;
}

.NewCampaign .form-group {
  margin-bottom: 15px;
}

.NewCampaign .control-label {
  margin-top: 0px;
}
