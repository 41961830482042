.Campaigns {
}

.Campaigns h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Campaigns h3 {
  font-size: 18px;
  line-height: 33px;
  color: #0b2e53;
  margin: 0px;
  margin-bottom: 20px;
}

.thanksOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 99999999;
  padding: 1%;
}

.thanksOverlay img {
  height: 200px;
  width: auto;
}

.warning-label {
  width: 100%;
  background-color: #e74c3c;
  color: #fff;
  text-align: center;
  padding: 5px;
}

.warning-label p {
  text-align: center !important;
  color: #fff !important;
  margin-bottom: 0px !important;
}
