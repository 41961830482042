.blue {
  color: #00D3FB !important;
  font-weight: 600 !important;
}

.Pricing {
  padding: 50px 0px;
}

.Pricing h3 {
  font-weight: 100;
  font-weight: 26px;
  line-height: 36px;
  color: #0b2e53;
  opacity: 0.6;
}

.Pricing small {
  text-transform: none;
}

.popular {
  border-right: 2px solid #f1f1f1;
  border-left: 2px solid #f1f1f1;
}

.line {
  box-sizing: border-box;
  height: 1px;
  width: 80%;
  border: 5px solid #0082f2;
  margin: 30px auto;
}

.dark {
  background-color: #33353a;
}

.darkBorder {
  border-color: #33353a;
}
