@font-face {
  font-family: "TradeGothic";
  src: local("TradeGothic"),
    url("../Fonts/trade-gothic-bold.woff") format("woff");
}

.Login h3 {
  opacity: 0.6;
}

.Login {
  padding: 2s0px 0px;
}
