.ViewAccount {
  padding: 20px 40px;
}

.ViewAccount h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
  margin-top: 0px;
}

.ViewAccount h3 {
  line-height: 33px;
  color: #0b2e53;
  margin: 0px;
}

.ViewAccount p {
  opacity: 1;
  margin-bottom: 0px;
}

.ViewAccount .btn-primary.smaller {
  margin-top: 0px;
  padding: 3px 10px;
  font-size: 14px;
  max-width: 150px;
  text-align: center;
}
